"use client";

import { BoxProps, Link, Stack, Typography, TypographyProps } from "@mui/material";

import { MhcAttributionFragment } from "graphqlApi/types";

import { sendGaNavigationEvent } from "common/util/googleAnalytics";

export interface StatAttributionProps {
  attribution: Omit<MhcAttributionFragment, "__typename">;
  component: BoxProps["component"];
  titleFontWeight?: TypographyProps["fontWeight"];
}

export const StatAttribution: React.FC<StatAttributionProps> = ({
  attribution,
  component,
  titleFontWeight = 400,
  ...props
}) => {
  const { url, name, citation } = attribution;
  return (
    <Stack gap="4px">
      <Typography component="span" fontWeight={titleFontWeight}>
        {name}
      </Typography>
      {url && (
        <Stack direction="row" sx={{ alignItems: "baseline" }} {...props}>
          <Link
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            variant="body2"
            title={`View additional information about and/or download ${name} dataset`}
            aria-label={`View or download ${name} dataset`}
            onClick={() =>
              sendGaNavigationEvent({
                category: "Data Download",
                action: "About the data click",
                label: name,
                ui_location: window.location.pathname
              })
            }
          >
            View or download this dataset
          </Link>
        </Stack>
      )}
      {citation && (
        <Stack direction="row" sx={{ alignItems: "baseline" }} {...props}>
          <Typography variant="body2" sx={{ wordBreak: "break-word" }}>
            <Typography component="span" variant="body2" fontWeight={600}>
              Citation:{" "}
            </Typography>
            {citation}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};
