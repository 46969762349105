"use client";

import "leaflet/dist/leaflet.css";

import { useCallback, useEffect, useMemo, useState } from "react";
import dynamic from "next/dynamic";

import { useIsMobile } from "common/util/hooks/useIsMobile";

import { BaseMapProps } from "common/components/GeoMap/BaseMap";
import { StateLoadingIndicator } from "./StateLoadingIndicator";

export type GeoMapProps = BaseMapProps;

// dynamic import to work around leaflet not working with SSG
export const GeoMap: React.FC<BaseMapProps> = ({ width = "100%", height = "100%", ...props }) => {
  const isMobile = useIsMobile();
  const [loading, setLoading] = useState(true);
  const Component = useMemo(
    () =>
      dynamic(async () => import("common/components/GeoMap/BaseMap"), {
        ssr: false // This line is important. It's what prevents server-side render
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.externallySelectedFeatureId, props.geoJSON]
  );
  const handleLoaded = useCallback(() => {
    setLoading(false);
  }, []);
  useEffect(() => {
    setLoading(true);
  }, [props.geoJSON]);
  return (
    <StateLoadingIndicator
      loading={loading || (props.forceLoading ?? false)}
      width={width}
      height={height}
      indicatorHeight={props.loadingIndicator?.height}
      sx={props.loadingIndicator?.sx}
      wrapperSx={{
        ...props.loadingIndicator?.wrapperSx,
        minHeight: isMobile ? props.minHeight : undefined
      }}
    >
      <Component onLoaded={handleLoaded} width={width} height={height} {...props} />
    </StateLoadingIndicator>
  );
};
